import React from "react";
import { Card, Fab, makeStyles, Tooltip } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import { ModalWindow } from "../../components/modal/Modal";
import { RideTime } from "../../type";
import { RideMenu } from "../../components/Menu";
import { layoutMaxDesktopWidth, listHoverColor, toolbarHeight } from "../../theme";
import { Add } from "@material-ui/icons";

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    position: "relative",
    top: toolbarHeight,
    height: `calc(100% - ${toolbarHeight}px)`,
    maxWidth: layoutMaxDesktopWidth,
    margin: "0 auto"
  },

  title: {
    textAlign: "center"
  },
  btn: {
    position: "fixed",
    bottom: 20,
    right: 30
  },
  rideCard: {
    display: "flex",
    alignItems: "center",
    padding: spacing(1),
    "&:hover": { backgroundColor: listHoverColor }
  },
  link: {
    textDecoration: "none",
    color: "black"
  },
  leftSide: {
    flexGrow: 1
  },
  rideName: {
    fontWeight: "bold"
  }
}));

type RideListProps = {
  rideTime: RideTime[];
  onChangeName: (name: string) => void;
  handleModal: () => void;
  onSave: () => void;
  onDelete: (id: string) => void;
  setCurrentRideTime: (time: string) => void;
  onSetNewRide: (ride: Blob) => void
  nameOfRide: string;
  open: boolean
};

export const RideList = ({
                           rideTime,
                           onDelete,
                           handleModal,
                           onChangeName,
                           onSave,
                           onSetNewRide,
                           nameOfRide,
                           open,
                           setCurrentRideTime
                         }: RideListProps) => {
  const classes = useStyles();

  return (
    <div className="wrapper">
      <div className={classes.root}>
        {rideTime.map((ride) => (
          <Card className={classes.rideCard} key={ride.id} square={true}>
            <div className={classes.leftSide}>
              <NavLink to={`rides/${ride.id}`} onClick={() => setCurrentRideTime(ride.difference)}
                       className={classes.link}>
                <div className={classes.rideName}>Název: {ride.name}</div>
                <div>Začátek jízdy: {ride.first}</div>
                <div>Celkový čas jízdy: {ride.difference}</div>
              </NavLink>
            </div>
            <div>
              <RideMenu index={ride.id} onDelete={onDelete} />
            </div>
          </Card>
        ))}
      </div>

      <Tooltip
        onClick={handleModal}
        title="Přidat jízdu"
        aria-label="add"
        className={classes.btn}
      >
        <Fab color="primary">
          <Add />
        </Fab>
      </Tooltip>
      <ModalWindow
        onChangeName={onChangeName}
        onSetNewRide={onSetNewRide}
        handleModal={handleModal}
        open={open}
        nameOfRide={nameOfRide}
        onSave={onSave}
      />
    </div>
  );
};
