import React, { FC } from 'react';
import { FilesFromDevice } from '../../type';
import {
  Dialog,
  DialogContent,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { GetApp, BatteryFull } from '@material-ui/icons';

const useStyles = makeStyles(() => ({
  battery: { display: 'flex', alignItems: 'center' },
}));

type ModalFilesType = {
  open: boolean;
  files: FilesFromDevice[] | undefined;
  onUpload: (args: FilesFromDevice) => void;
  battery: number;
  onClose: () => void;
};

export const ModalFiles: FC<ModalFilesType> = ({
  open,
  files,
  onClose,
  battery,
  onUpload,
}) => {
  const classes = useStyles()
  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogContent>
        <div className={classes.battery}>
          <BatteryFull />
          <Typography>{battery}%</Typography>
        </div>

        <List>
          {files?.map(({ file, size }, index) => {
            return (
              <ListItem divider key={index}>
                <ListItemText
                  primary={file}
                  secondary={`File size: ${size}kb`}
                />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="Download"
                    onClick={() => onUpload({ file, size })}
                  >
                    <GetApp />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>
      </DialogContent>
    </Dialog>
  );
};
