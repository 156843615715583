import { createMuiTheme } from "@material-ui/core/styles";
import { blue, green, grey, red } from "@material-ui/core/colors";

// custom layout
export const toolbarHeight = 30;
export const maxDesktopDrawerHeight = 540;
export const maxMobileDrawerHeight = 348;
export const layoutMaxDesktopWidth = 1280;

// Colors
const hoverBackgroundColor = grey["100"]
const headerBackgroundColor = grey["A100"]

// custom Colors
export const listHoverColor = hoverBackgroundColor;
export const tabHoverColor = hoverBackgroundColor;
export const lapsHeaderColor = headerBackgroundColor
export const brakeColor = red["600"]
export const gasColor = green["A400"]
export const rotationColor = blue["A400"]
export const accelerationColor = red["A400"]

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#ed1d24"
    }
  }
});
