import { nanoid } from 'nanoid';
import { getAllRides } from '../../database';
import { DrivePoint, parseMessageWithTime } from './binaryUtils';
import { Ride, RideInfo, RideTime } from '../../type';
import { parseDate } from '../time/DateTime';
import {
  getMinMaxGasBrakeValue,
  getPercentOfCurrentGasBrakeValue,
  getShortFloatNumber,
} from '../../utils/utils';
import { lapsSplitter } from '../circuits/lapsSplitter';
import { timeDiff } from '../time/timeDifference';

export const transformBufferToJson = async (
  buffer: Blob
): Promise<DrivePoint[]> => {
  const reader = new FileReader();
  return new Promise((resolve) => {
    reader.readAsArrayBuffer(buffer);
    reader.onload = ({ target }: any) => {
      const buffer = Buffer.from(target?.result);
      return resolve(parseMessageWithTime(buffer));
    };
  });
};

export const getTimeOfRides = async (): Promise<RideTime[]> => {
  const rides = await getAllRides();

  return rides.map(({ data: { rideWithCoords }, name, id }) => {
    const length = rideWithCoords.length - 1;

    const firstTime = parseDate(`${rideWithCoords[0].time}`);
    const difference = timeDiff(
      rideWithCoords[length].time,
      rideWithCoords[0].time
    );

    return {
      name,
      id,
      first: firstTime,
      difference,
    };
  });
};

export const transformFromDrivePointToRideInfo = (drive: DrivePoint[]) => {
  const { minGasBrakeValue, maxGasBrakeValue } =
    getMinMaxGasBrakeValue(drive);
  return drive
    .filter((ride) => ride.gps && ride.rotations && ride.accelerations)
    .map((ride) => {
      const { rotations, accelerations, realTime } = ride;
      const frontal = getPercentOfCurrentGasBrakeValue(
        minGasBrakeValue,
        maxGasBrakeValue,
        //@ts-ignore
        accelerations.frontal
      );

      return {
        ...ride,
        time: realTime,
        //@ts-ignore
        rotation: Math.floor(getShortFloatNumber(rotations.lateral, 2)),
        //@ts-ignore
        acceleration: getShortFloatNumber(accelerations.lateral, 2),
        frontal,
      };
    })
    .map(({ gps, time, rotation, acceleration, frontal }) => ({
      lat: gps ? gps.lat : 0,
      lng: gps ? gps.lng : 0,
      speed: gps ? gps.speed : 0,
      time,
      rotation,
      acceleration,
      frontal,
    }));
};

export const getRidesWithCoords = async (
  data: Blob,
  nameOfRide: string
): Promise<Ride> => {
  const jsonRide = await transformBufferToJson(data);
  const laps = lapsSplitter(jsonRide);

  const startOfRide = new Date(jsonRide[0].realTime).toLocaleTimeString();

  const { minGasBrakeValue, maxGasBrakeValue } =
    getMinMaxGasBrakeValue(jsonRide);

  const ridesWithCoord: RideInfo[] = transformFromDrivePointToRideInfo(jsonRide);

  return {
    name: nameOfRide ? nameOfRide : 'Nepojmenovaná jízda',
    startOfRide,
    data: {
      rideWithCoords: ridesWithCoord,
      laps,
      minMaxGasBrakeValue: {
        minGasBrakeValue,
        maxGasBrakeValue,
      },
    },
    id: nanoid(),
  };
};
