import Dexie from 'dexie';
import relationships from 'dexie-relationships';
import { Ride } from '../type';

class CapiDatabase extends Dexie {
  ride!: Dexie.Table<Ride, string>;

  constructor() {
    super('6sence', { addons: [relationships] });
    this.version(2).stores({
        ride: 'id, data, name',
    });
  }
}

export const db = new CapiDatabase();
