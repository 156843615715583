import React, { FC } from "react";
import { Card, makeStyles, Typography } from "@material-ui/core";
import { GasBrakeValueType } from "../../type";
import { brakeColor, gasColor, toolbarHeight } from "../../theme";
import classNames from "classnames";

const useStyles = makeStyles(({ palette, spacing, breakpoints }) => ({
  infoBlock: {
    position: "absolute",
    left: 10,
    top: toolbarHeight + 180, // toolbar height + google legend
    transform: "translateY(-50%)",
    zIndex: 99,
    padding: spacing(1),
    width: 300,
    backgroundColor: palette.common.white,
    [breakpoints.down("sm")]: {
      width: 150
    }
  },
  actionBlockWrapper: {
    margin: "10px 0"
  },
  actionBlockNames: {
    display: "flex",
    justifyContent: "space-between"
  },
  actionsBlock: {
    border: "2px solid #000",
    height: 50,
    display: "flex",
    backgroundColor: palette.common.white,
    [breakpoints.down("sm")]: {
      height: 25
    }
  },
  actionBrake: {
    borderLeft: "2px solid #000",
    width: "50%",
    height: "100%",
    transform: "rotateY(180deg)"
  },
  actionGas: {
    width: "50%",
    height: "100%"
  },

  actionLabel: {
    fontWeight: "bold"
  },
  brakeLabel: {
    color: brakeColor
  },

  accelerateLabel: {
    color: gasColor
  },

  timeLabel: {
    marginTop: spacing(2),
    [breakpoints.down("sm")]: {
      marginTop: spacing(1)
    }
  },

  brakeColor: {
    height: "100%",
    backgroundColor: brakeColor,
    //@ts-ignore
    width: ({ percentValue, isGas }) => (isGas ? 0 : `${percentValue}%`)
  },
  gasColor: {
    //@ts-ignore
    width: ({ percentValue, isGas }) => (isGas ? `${percentValue}%` : 0),
    backgroundColor: gasColor,
    height: "100%"
  }
}));

type TooltipProps = {
  speed: number,
  time: Date
  rotation: number
  acceleration: number;
  gasBrakeValue: GasBrakeValueType | null
}

export const Tooltip: FC<TooltipProps> = ({ speed, time, rotation, acceleration, gasBrakeValue }) => {
  const { isGas, percentValue } = gasBrakeValue || {};

  const classes = useStyles({ isGas, percentValue });

  return (
    <Card className={classes.infoBlock}>

      <div className={classes.actionBlockWrapper}>
        <div className={classes.actionBlockNames}>
          <span className={classNames(classes.actionLabel, classes.brakeLabel)}>Zpomalení</span>
          <span className={classNames(classes.actionLabel, classes.accelerateLabel)}>Zrychlení</span>
        </div>
        <div className={classes.actionsBlock}>
          <div className={classes.actionBrake}>
            <div className={classes.brakeColor} />
          </div>
          <div className={classes.actionGas}>
            <div className={classes.gasColor} />
          </div>
        </div>
      </div>
      <Typography className={classes.actionLabel}>Rychlost: {Math.floor(speed)} km/h</Typography>
      <Typography>Náklon: {Math.abs(rotation)}°</Typography>
      <Typography>Přetížení: {Math.abs(acceleration)} G</Typography>
      <Typography className={classes.timeLabel}>Čas: {time.toLocaleTimeString()}</Typography>
    </Card>
  );
};
