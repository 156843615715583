import { Divider, makeStyles } from "@material-ui/core";
import React, { FC, memo } from "react";
import { MinMaxGasBrakeValueType, RideInfo } from "../../type";
import { onMouseEvent } from "../googleMap/GoogleMap";
import { AreaChartComponent } from "./chartComponents/AreaChartComponent";
import { LineChartComponent } from "./chartComponents/LineChartComponent";
import { accelerationColor, gasColor, rotationColor } from "../../theme";
import classNames from "classnames";

const useStyles = makeStyles(({ spacing }) => ({

  root: {
    marginTop: spacing(1)
  },

  graphWithTitle: {
    display: "flex",
    width: "100%"
  },
  graphTitle: {
    width: 50,
    paddingLeft: 10,
    margin: 0
  },

  noWrap: {
    whiteSpace: "nowrap"
  },

  divider: {
    marginBottom: spacing(1)
  }
}));

type GraphProps = {
  coords: RideInfo[];
  minMaxGasBrakeValue: MinMaxGasBrakeValueType;
  onMouse: onMouseEvent
  onMouseOut: () => void;
};

export const DesktopGraphs: FC<GraphProps> = memo(
  ({ coords, minMaxGasBrakeValue, onMouse, onMouseOut }) => {
    const classes = useStyles();

    return (
      <div className={classes.root}>
        <div className={classes.graphWithTitle}>
          <p className={classes.graphTitle}>Rychlost km/h</p>
          <LineChartComponent
            label="Rychlost"
            ride={coords}
            dataKey="speed"
            color={gasColor}
            onMouseOut={onMouseOut}
            onMouse={onMouse}
          />
        </div>
        <Divider className={classes.divider} />
        <div className={classes.graphWithTitle}>
          <p className={classes.graphTitle}>Brzda / Plyn </p>
          <AreaChartComponent
            coords={coords}
            onMouse={onMouse}
            minMaxGasBrakeValue={minMaxGasBrakeValue}
          />
        </div>
        <Divider className={classes.divider} />

        <div className={classes.graphWithTitle}>
          <p className={classNames(classes.graphTitle, classes.noWrap)}>Náklon °</p>
          <LineChartComponent
            ride={coords}
            dataKey="rotation"
            onMouse={onMouse}
            color={rotationColor}
            onMouseOut={onMouseOut}
            label="Náklon"
          />
        </div>
        <Divider className={classes.divider} />

        <div className={classes.graphWithTitle}>
          <p className={classNames(classes.graphTitle, classes.noWrap)}>Přetížení G</p>
          <LineChartComponent
            ride={coords}
            dataKey="acceleration"
            onMouse={onMouse}
            color={accelerationColor}
            onMouseOut={onMouseOut}
            label="Přetížení"
          />
        </div>
      </div>
    );
  }
);
