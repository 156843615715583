import React, { FC, useEffect, useState } from "react";
import { connect, downloadFile, loadBattery, tryToConnect } from "../../utils/bluetoothAPI";
import { FilesFromDevice } from "../../type";
import { ModalFiles } from "./ModalFiles";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputLabel,
  makeStyles,
  TextField
} from "@material-ui/core";
import { Loading } from "../Loading";
import { Done } from "@material-ui/icons";
import { isMobile } from "react-device-detect";

const useStyles = makeStyles(({ spacing, palette }) => ({

  input: {
    marginBottom: 20
  },

  uploadButton: {
    minWidth: 186
  },

  dialogActions: {
    justifyContent: "space-between",
    padding: spacing(2, 3)
  },
  uploadButtonsContainer: {
    marginBottom: 20
  },

  uploadButtonWithProgress: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },

  successIcon: {
    color: palette.success.main
  },

  inputFileLabel: {
    marginBottom: 20,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  }
}));

type ModalWindowProps = {
  open: boolean;
  nameOfRide: string;
  onSetNewRide: (data: Blob) => void;
  onChangeName: (value: string) => void;
  handleModal: () => void;
  onSave: () => void;
};

export const ModalWindow: FC<ModalWindowProps> = ({
                                                    open,
                                                    nameOfRide,
                                                    onSetNewRide,
                                                    onChangeName,
                                                    handleModal,
                                                    onSave
                                                  }) => {
  const classes = useStyles();
  const [disabled, setDisabled] = useState(true);
  const [files, setFiles] = useState<FilesFromDevice[]>();
  const [battery, setBattery] = useState<number>(90);
  const [isFilesModal, setIsFilesModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isUploadedFromPC, setIsUploadedFromPC] = useState(false);
  const [isUploadedFromDevice, setIsUploadedFromDevice] = useState(false);

  const onUploadFileFromDevice = async ({ file, size }: FilesFromDevice) => {
    setLoading(true);
    try {
      const downloadedFile = await downloadFile({ file, size });
      onSetNewRide(downloadedFile);
      setIsUploadedFromDevice(true);
      setDisabled(false);
    } catch (e) {
      setDisabled(true);
    } finally {
      setLoading(false);
      setIsFilesModal(false);
    }
  };

  const onUploadFileFromPC = (event: React.FormEvent<HTMLInputElement>) => {
    const files = event.currentTarget.files;
    setDisabled(false);
    if (files) {
      onSetNewRide(files[0]);
      setIsUploadedFromPC(true);
    }
  };

  const onOpenCloseFilesModal = () => setIsFilesModal((prev) => !prev);

  const onConnect = async () => {
    try {
      const server = await tryToConnect();
      setLoading(true);
      const files: FilesFromDevice[] = await connect(server);
      const battery: number = await loadBattery();
      setLoading(false);
      setFiles(files);
      setIsFilesModal(true);
      setBattery(battery);
    } catch (e) {
      setLoading(false);
    }
  };

  useEffect(() => {
    setIsUploadedFromPC(false);
    setIsUploadedFromDevice(false);
    setDisabled(true);
  }, [open]);

  return (
    <>
      {loading && <Loading />}
      <Dialog open={open} onClose={handleModal} fullWidth fullScreen={isMobile && true}>
        <DialogTitle>Nahrání dat</DialogTitle>
        <DialogContent>
          <TextField
            className={classes.input}
            id="standard-basic"
            label="Název jízdy"
            value={nameOfRide}
            onChange={(e) => onChangeName(e.target.value)}
            fullWidth
          />
          <input
            style={{ display: "none" }}
            id="file"
            type="file"
            onChange={onUploadFileFromPC}
          />
          <div className={classes.uploadButtonsContainer}>
            <div className={classes.uploadButtonWithProgress}>
              <InputLabel
                htmlFor="file"
                className={classes.inputFileLabel}
              >
                <Button
                  className={classes.uploadButton}
                  color="primary"
                  variant="contained"
                  component="span"
                  disabled={isUploadedFromDevice}
                >
                  Nahrát z PC
                </Button>
                {isUploadedFromPC && <Done className={classes.successIcon} />}
              </InputLabel>
            </div>

            <div className={classes.uploadButtonWithProgress}>
              <Button
                className={classes.uploadButton}
                disabled={isUploadedFromPC}
                variant="contained"
                color="primary"
                onClick={onConnect}
              >
                Nahrát ze zařízení
              </Button>
              {isUploadedFromDevice && <Done className={classes.successIcon} />}
            </div>
          </div>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button variant="contained" color="default" onClick={handleModal}>
            Zrušit
          </Button>
          <Button
            disabled={disabled}
            variant="contained"
            color="primary"
            onClick={onSave}
          >
            Uložit
          </Button>
        </DialogActions>
      </Dialog>
      <ModalFiles
        open={isFilesModal}
        files={files}
        onClose={onOpenCloseFilesModal}
        battery={battery}
        onUpload={onUploadFileFromDevice}
      />
    </>
  );
};
