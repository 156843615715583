export interface LatLng {
    lat: number;
    lng: number;
}

export interface Circuit {
    name: string;
    start: LatLng;
    center: LatLng;
}

export const circuitZdar: Circuit = {
    name: 'Žďár nad Sázavou',
    start: {
        lat: 49.56195054263058,
        lng: 16.069364341319428,
    },
    center: {
        lat: 49.559873,
        lng: 16.064099,
    }
};

export const circuitJihlava: Circuit = {
    name: 'Jihlava',
    start: {
        lat: 49.405255,
        lng: 15.563921,
    },
    center: {
        lat: 49.405145,
        lng: 15.564605
    }
};

const circuitSosnova: Circuit = {
    name: 'Polygon Sosnová',
    start: {
        lat: 50.658605,
        lng: 14.540074
    },
    center: {
        lat: 50.659510,
        lng: 14.538856
    }
};

export const circuits: Circuit[] = [
    circuitZdar,
    circuitJihlava,
    circuitSosnova
];

export const circuitDistanceThreshold = 0.1;
export const startPointsDistanceThreshold = 0.0004;
