import React, { FC, useEffect, useState } from 'react';
import { addNewRide, deleteRide } from '../../database';
import { RideTime } from '../../type';
import { RideList } from './RideList';

type RideListContainerProps = {
  rideTime: RideTime[];
  setCurrentRideTime: (time: string) => void
  setTitle: (title: string) => void;
  getData: () => void;
};

export const RideListContainer: FC<RideListContainerProps> = ({
  getData,
  setTitle,
  rideTime,
  setCurrentRideTime
}) => {
  const [open, setOpen] = useState(false);
  const [nameOfRide, setName] = useState('');
  const [ride, setRide] = useState<Blob>(new Blob());

  const onChangeName = (value: string) => {
    setName(value);
  };

  const handleModal = () => {
    setName('');
    setOpen(!open);
  };

  const onSave = async () => {
    setName('');
    setOpen(!open);
    await addNewRide(ride, nameOfRide);
    getData();
  };
  const onDelete = (id: string) => {
    deleteRide(id);
    getData();
  };

  const onSetNewRide = (data: Blob) => {
    setRide(data);
  };

  useEffect(() => {
    setTitle('Seznam jízd');
  }, [setTitle]);

  return (
    <RideList
      rideTime={rideTime}
      onChangeName={onChangeName}
      handleModal={handleModal}
      onSave={onSave}
      onDelete={onDelete}
      open={open}
      nameOfRide={nameOfRide}
      onSetNewRide={onSetNewRide}
      setCurrentRideTime={setCurrentRideTime}
    />
  );
};
