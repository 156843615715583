import React, { FC } from 'react';
import {
  XAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  LineChart,
  Line,
  Legend,
} from 'recharts';
import { RideInfo } from '../../type';
import { onMouseEvent } from '../googleMap/GoogleMap';
import { ActivePayloadType } from './chartComponents/LineChartComponent';
import { accelerationColor, gasColor, rotationColor } from "../../theme";

type GraphProps = {
  coords: RideInfo[];
  onClick: onMouseEvent;
};

export const MobileGraphs: FC<GraphProps> = ({ coords, onClick }) => {

  const onChartClick = (payloadEvent: ActivePayloadType | null) => {
    if (payloadEvent) {
      const { payload } = payloadEvent.activePayload[0];
      onClick(payload)
    }
  };

  return (
    <ResponsiveContainer width="100%" height={300}>
      <LineChart data={coords} onClick={onChartClick}>
        <CartesianGrid />
        <XAxis dataKey="name" />
        <Tooltip formatter={(value: any) => Math.floor(value)} />
        <Legend fontStyle={18} iconSize={0} verticalAlign="top" />
        <Line
          dot={false}
          type="monotone"
          dataKey="speed"
          stroke={gasColor}
          name="Rychlost"
        />
        <Line
          dot={false}
          type="monotone"
          dataKey="rotation"
          stroke={rotationColor}
          name="Náklon"
        />
        <Line
          dot={false}
          type="monotone"
          dataKey="acceleration"
          stroke={accelerationColor}
          name="Přetížení"
        />
      </LineChart>
    </ResponsiveContainer>
  );
};
