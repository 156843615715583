import React, { FC, useState } from 'react';

import {
  Button,
  Card,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { RideInfo } from '../../type';
import { lapsHeaderColor } from '../../theme';

type CirclesProps = {
  lapTime: string[];
  totalRideTime: string;
  onLapSelect: (ride: RideInfo[] | undefined) => void;
  laps: RideInfo[][];
};

const useStyles = makeStyles(({ spacing }: Theme) => ({
  circlesRoot: {
    height: '100%',
    padding: spacing(2),
  },

  totalRideTimeWithButton: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  lapsHeader: {
    backgroundColor: lapsHeaderColor,
    marginTop: spacing(1),
    fontWeight: 'bold',
    padding: spacing(0.5, 2),
  },
}));

export const Circles: FC<CirclesProps> = ({
  laps,
  lapTime,
  totalRideTime,
  onLapSelect,
}) => {
  const classes = useStyles();
  const [selectedLap, setSelectedLap] = useState<number | null>(null);

  const onClick = (lap: RideInfo[] | undefined, index: number | null) => {
    setSelectedLap(index);
    onLapSelect(lap);
  };

  const lapsList = laps.map((lap, index) => {
    const timeOfCurrentLap = lapTime[index];
    return (
      <ListItem
        button
        key={index}
        onClick={() => onClick(lap, index)}
        selected={selectedLap === index}
      >
        <ListItemText
          primary={`Kolo ${index + 1}`}
          secondary={`Čas kola: ${timeOfCurrentLap}`}
        />
      </ListItem>
    );
  });

  return (
    <div className={classes.circlesRoot}>
      <div className={classes.totalRideTimeWithButton}>
        <Typography>Celkový čas jízdy: {totalRideTime}</Typography>
        <Button variant="contained" onClick={() => onClick(undefined, null)}>
          Zobrazit celkovou jízdu
        </Button>
      </div>
      <List
        subheader={<div className={classes.lapsHeader}>Jednotlivá kola:</div>}
      >
        <Card square>{lapsList}</Card>
      </List>
    </div>
  );
};
