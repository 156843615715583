// @ts-ignore
import {applySpec, init, map, pipe, tail, zip} from 'ramda';

const KMH = 3.6

const unshift = (bit: number, len: number) => (n: number) => {
  const mask = Math.pow(2, len) - 1;
  const shiftedMask = mask << bit;
  const and = n & shiftedMask;
  return and >> bit;
};

interface StorageContent {
    poi: number;
    end: number;
    gpsData: number;
    linAccData: number;
    grvData: number;
}

const byteToStorageContent = (n: number): StorageContent =>
  applySpec({
    poi: unshift(0, 1),
    end: unshift(1, 1),
    gpsData: unshift(2, 2),
    linAccData: unshift(4, 1),
    grvData: unshift(5, 1),
  })(n);

interface BufferReader {
    readStorageContent: () => StorageContent,
    readInt8: () => number;
    readUInt8: () => number;
    readInt16: () => number;
    readUInt16: () => number;
    readInt32: () => number;
    readUInt32: () => number;
    readFloat: () => number;
    readDouble: () => number;
    hasData: () => boolean;
    getOffset: () => number;
    length: number,
    rest: () => Buffer,
}

const createBufferReader = (buffer: Buffer): BufferReader => {
  let offset = 0;

  return {
    readStorageContent: () => byteToStorageContent(buffer[offset++]),
    readInt8: () => buffer.readInt8(offset++),
    readUInt8: () => buffer.readUInt8(offset++),
    readInt16: () => {
      const res = buffer.readInt16LE(offset);
      offset += 2;
      return res;
    },
    readUInt16: () => {
      const res = buffer.readUInt16LE(offset);
      offset += 2;
      return res;
    },
    readInt32: () => {
      const res = buffer.readInt32LE(offset);
      offset += 4;
      return res;
    },
    readUInt32: () => {
      const res = buffer.readUInt32LE(offset);
      offset += 4;
      return res;
    },
    readFloat: () => {
      const res = buffer.readFloatLE(offset);
      offset += 4;
      return res;
    },
    readDouble: () => {
      const res = buffer.readDoubleLE(offset);
      offset += 8;
      return res;
    },
    hasData: () => offset < buffer.length,
    getOffset: () => offset,
    length: buffer.length,
    rest: () => buffer.subarray(offset, buffer.length),
  };
};

interface GpsData {
    satellites: number;
    hdop: number;

    lat: number;
    lng: number;
    age: number;

    alt: number;

    date?: Date;

    speed: number;

    lapStart?: boolean;
}

const readGpsData = (reader: BufferReader): GpsData => {
  return {
    satellites: reader.readInt8(),
    hdop: reader.readInt8(),

    lat: reader.readDouble(),
    lng: reader.readDouble(),
    age: reader.readUInt32(),

    alt: reader.readFloat(),

    date: new Date(
      Date.UTC(
        reader.readUInt16(), // year
        reader.readUInt8(), // month
        reader.readUInt8(), // day
        reader.readUInt8(), // hour
        reader.readUInt8(), // minute
        reader.readUInt8(), // second
        reader.readUInt8() // centisecond
      )
    ),

    speed: reader.readFloat() * KMH,
  };
};

interface GrvData {
    roll: number;
    pitch: number;
    yaw: number;
}

const readGrvData = (reader: BufferReader): GrvData => ({
  roll: reader.readFloat(),
  pitch: reader.readFloat(),
  yaw: reader.readFloat(),
});

interface LinAccData {
    x: number;
    y: number;
    z: number;
}

const readLinAccData = (reader: BufferReader): LinAccData => ({
  x: reader.readFloat(),
  y: reader.readFloat(),
  z: reader.readFloat(),
});

interface RawDrivePoint {
    poi?: boolean;
    end?: boolean;
    timestamp: number;
    gps?: GpsData;
    linAcc?: LinAccData;
    grv?: GrvData;
}

const readOneRawDrivePoint = (reader: BufferReader): RawDrivePoint => {
  const content = reader.readStorageContent();

  const out: RawDrivePoint = {
      timestamp: reader.readUInt32()
  };

  if (content.poi) {
    out.poi = content.poi !== 0;
  }

  if (content.end) {
    out.end = content.end !== 0;
  }

  if (content.gpsData) {
    out.gps = readGpsData(reader);

    if (content.gpsData === 1) {
      out.gps.lapStart = true;
    }
  }

  if (content.linAccData) {
    out.linAcc = readLinAccData(reader);
  }

  if (content.grvData) {
    out.grv = readGrvData(reader);
  }

  return out;
};

const readAllRawDrivePoints = (reader: BufferReader): RawDrivePoint[] => {
  const packets = [];

  while (reader.hasData()) {
    try {
      const packet = readOneRawDrivePoint(reader);

      packets.push(packet);
    } catch {
      console.warn('Some data remains');
      break;
    }
  }

  return packets;
};

interface TimeInterval {
    fromTs: number;
    toTs: number;
    fromDate: Date;
}

interface TimePoint {
    timestamp: number;
    date: Date;
}

const getTimeIntervals = (drivePoints: DrivePoint[]): TimeInterval[] => {
    const withGps = drivePoints.filter(x => x.gps);
    // @ts-ignore
    const points = withGps.map((x: TimePoint) => ({timestamp: x.timestamp, date: x.gps.date}));

    const firstLineTimestamp = drivePoints[0].timestamp;
    const firstWithGps = withGps[0];
    if (!firstWithGps || !firstWithGps.gps) {
        return [];
    }

    const firstWithGpsDate = firstWithGps.gps.date;
    if (!firstWithGpsDate) {
        return [];
    }

    const firstWithGpsTimestamp = firstWithGps.timestamp;

    const firstTimePointDate = new Date(firstWithGpsDate.getTime() - (firstWithGpsTimestamp - firstLineTimestamp) / 1000);
    const firstTimePoint = {timestamp: firstLineTimestamp, date: firstTimePointDate};
    const lastTimePoint = {timestamp: Infinity};

    const startPoints = [firstTimePoint, ...init(points)];
    const endPoints = [...tail(points), lastTimePoint];

    return pipe(
        zip,
        // @ts-ignore
        map(([from, to]) => ({fromTs: from.timestamp, toTs: to.timestamp, fromDate: from.date})),
    )(startPoints, endPoints);
};

const findTimeIntervals = (intervals: TimeInterval[]) => (timestamp: number) => intervals.find(({fromTs, toTs}) => fromTs <= timestamp && toTs > timestamp);

const addRealTimeToDrivePoint = (intervalFinder: (timestamp: number) => TimeInterval | undefined) => (drivePoint: DrivePoint): DrivePoint => {
    const interval = intervalFinder(drivePoint.timestamp);
    if (!interval) {
        return drivePoint;
    }

    if (drivePoint.gps) {
        delete drivePoint.gps.date;
    }

    const time = interval.fromDate.getTime();
    const offset = (drivePoint.timestamp - interval.fromTs) / 1000

    drivePoint.realTime = new Date(time + offset);

    return drivePoint;
};

const addRealTimeToDrivePoints = (drivePoints: DrivePoint[]): DrivePoint[] => {
    const timeIntervals = getTimeIntervals(drivePoints);
    const intervalFinder = findTimeIntervals(timeIntervals);

    return drivePoints.map(addRealTimeToDrivePoint(intervalFinder));
};

const readVersionByte = (reader: BufferReader) => {
  console.log('Version is', reader.readUInt8());

  return reader;
};

export interface DrivePoint {
    index?: number;
    gpsIndex?: number;
    lapStart?: boolean;
    lapEnd?: boolean;
    timestamp: number;
    lapTimestamp?: number;
    realTime: Date
    poi?: boolean;
    end?: boolean;
    gps?: GpsData;
    accelerations?: {
        frontal: number;
        lateral: number;
    },
    rotations?: {
        lateral: number;
    }
}

const rawDrivePointsToDrivePoints = (rawDrivePoint: RawDrivePoint): DrivePoint => {
    const drivePoint: DrivePoint = {
        timestamp: rawDrivePoint.timestamp,
        realTime: new Date(0)
    };

    if (rawDrivePoint.poi) {
        drivePoint.poi = rawDrivePoint.poi;
    }

    if (rawDrivePoint.end) {
        drivePoint.end = rawDrivePoint.end;
    }

    if (rawDrivePoint.gps) {
        drivePoint.gps = rawDrivePoint.gps;
    }

    if (rawDrivePoint.linAcc) {
        drivePoint.accelerations = {
            frontal: -rawDrivePoint.linAcc.y,
            lateral: -rawDrivePoint.linAcc.x
        }
    }

    if (rawDrivePoint.grv) {
        drivePoint.rotations = {
            lateral: -rawDrivePoint.grv.pitch
        };
    }

    return drivePoint;
};

// jako vstup očekává Buffer
// výstup je pole objektů ve tvaru viz https://gitlab.commity.cz/6sence/6sence/-/issues/26
const parseMessage = (buffer: Buffer) => {
    const bufferReader = createBufferReader(buffer);
    readVersionByte(bufferReader);

    const rawDrivePoints = readAllRawDrivePoints(bufferReader);

    return rawDrivePoints.map(rawDrivePointsToDrivePoints);
};

export const parseMessageWithTime = (buffer: Buffer): DrivePoint[] => {
    const drivePoints = parseMessage(buffer);
    const drivePointsWithTime = addRealTimeToDrivePoints(drivePoints);
    return drivePointsWithTime.map((point, index) => {
        point.index = index;

        return point;
    });
};
