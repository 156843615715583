import {
    Circuit,
    circuitDistanceThreshold,
    circuits,
    LatLng,
    startPointsDistanceThreshold
} from "../../database/circuits";
import {DrivePoint} from "../trasnform/binaryUtils";
import {findCircuit} from "./circuitsFinder";
import {findLocalMinima, manhattanDistance, pythagorasDistance} from "./math";

//@ts-ignore
import {insertAll, reverse} from 'ramda';
import { transformFromDrivePointToRideInfo } from "../trasnform/transformData";
import { RideInfo } from "../../type";

const recomputeTimes = (laps: DrivePoint[][]) => {
     const tsZero = laps[0][0].timestamp;

     return laps.map(lap => {
         const lapTsZero = lap[0].timestamp;

         return lap.map(point => {
             point.lapTimestamp = point.timestamp - lapTsZero;
             point.timestamp = point.timestamp - tsZero;

             return point;
         });
     });
};

const distanceToCircuitStart = (circuit: Circuit) => (drivePoint: DrivePoint) => {
    const manhattan = manhattanDistance(circuit.start, drivePoint.gps);

    return manhattan > startPointsDistanceThreshold ? Infinity : manhattan;
};

const splitDriveUsingMetaPoints = (drivePoints: DrivePoint[]): DrivePoint[][] => {
    const splitIndex = drivePoints.findIndex(point => point.lapEnd);

    if (splitIndex === -1) {
        return [ drivePoints ];
    }

    if (drivePoints.length === 0) {
        return [];
    }

    const lap = drivePoints.slice(0, splitIndex + 1);
    const rest = drivePoints.slice(splitIndex + 1);

    return [
        lap,
        ...splitDriveUsingMetaPoints(rest),
    ];
};

const getCloserPoint = (center: DrivePoint, p1: DrivePoint, p2: DrivePoint) =>
    pythagorasDistance(center.gps, p1.gps) < pythagorasDistance(center.gps, p2.gps)
        ? p1
        : p2

const insertAllAtTimestamp = (
    ts: number,
    toInsert: DrivePoint[],
    points: DrivePoint[]
): DrivePoint[] => {
    let i;
    for (i = 0; i < points.length; i++) {
        if (points[i].timestamp > ts) {
            break;
        }
    }

    return insertAll(i, toInsert, points);
};

const addLapStartStopForPoint = (lapStart: LatLng, gpsPoints: DrivePoint[]) => (points: DrivePoint[], nearestPoint: DrivePoint) => {
    const leftGpsNB = gpsPoints[(nearestPoint.gpsIndex || 0) - 1];
    const rightGpsNB = gpsPoints[(nearestPoint.gpsIndex || 0) + 1];
    const secondNearestPoint = getCloserPoint(nearestPoint, leftGpsNB, rightGpsNB);

    if (!nearestPoint.gps || !secondNearestPoint.gps) {
        return points;
    }

    const distanceFromStartToNearestPoint = pythagorasDistance(lapStart, nearestPoint.gps);
    const distanceFromStartToSecondNearestPoint = pythagorasDistance(lapStart, secondNearestPoint.gps);
    const distanceRatio = distanceFromStartToNearestPoint / distanceFromStartToSecondNearestPoint;

    const tsDiff = nearestPoint.timestamp - secondNearestPoint.timestamp;
    const timeDiff = nearestPoint.realTime.getTime() - secondNearestPoint.realTime.getTime();
    const latDiff = nearestPoint.gps.lat - secondNearestPoint.gps.lat;
    const lngDiff = nearestPoint.gps.lng - secondNearestPoint.gps.lng;
    const speedDiff = nearestPoint.gps.speed - secondNearestPoint.gps.speed;

    const metaPoint: DrivePoint = {
        timestamp: nearestPoint.timestamp - tsDiff * distanceRatio,
        realTime: new Date(nearestPoint.realTime.getTime() - (timeDiff * distanceRatio) / 1000),
        gps: {
            satellites: nearestPoint.gps.satellites,
            hdop: nearestPoint.gps.hdop,

            lat: nearestPoint.gps.lat - latDiff * distanceRatio,
            lng: nearestPoint.gps.lng - lngDiff * distanceRatio,
            age: nearestPoint.gps.age,

            alt: nearestPoint.gps.alt,

            speed: nearestPoint.gps.speed - speedDiff * distanceRatio
        }
    };

    const endMetaPoint: DrivePoint = {
        ...metaPoint,
        lapEnd: true
    }

    const startMetaPoint: DrivePoint = {
        ...metaPoint,
        lapStart: true
    };

    return insertAllAtTimestamp(
        metaPoint.timestamp,
        [endMetaPoint, startMetaPoint],
        points
    );

};

const addLapStartStopMetaPoints = (
    lapStart: LatLng,
    drivePoints: DrivePoint[],
    gpsPoints: DrivePoint[],
    nearestPointsToStart: DrivePoint[]
) => (reverse(nearestPointsToStart) as DrivePoint[])
    .reduce(addLapStartStopForPoint(lapStart, gpsPoints), drivePoints)
    .map(point => {
        delete point.index;
        delete point.gpsIndex;

        return point;
    });

export const lapsSplitter = (drivePoints: DrivePoint[]): RideInfo[][] => {
    const circuit = findCircuit(circuits, drivePoints, circuitDistanceThreshold);
    if (!circuit) {
        return [drivePoints].map(transformFromDrivePointToRideInfo);
    }

    const gpsPoints = drivePoints.filter(p => p.gps).map((point, index) => {
        point.gpsIndex = index;
        return point;
    });

    const nearestPointsToStart = findLocalMinima(gpsPoints, distanceToCircuitStart(circuit));
    const withStartStopPoints = addLapStartStopMetaPoints(circuit.start, drivePoints, gpsPoints, nearestPointsToStart);
    const lapsWithStartAndEndPart = splitDriveUsingMetaPoints(withStartStopPoints);

    if (lapsWithStartAndEndPart.length <= 2) {
        return recomputeTimes([ withStartStopPoints ]).map(transformFromDrivePointToRideInfo);;
    }
    return recomputeTimes(lapsWithStartAndEndPart.slice(1, lapsWithStartAndEndPart.length - 1)).map(transformFromDrivePointToRideInfo);
};
