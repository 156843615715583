import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { GoogleAPI, GoogleApiWrapper, Map } from "google-maps-react";
import { Marker } from "@react-google-maps/api";
import { onMouseProps, PolylineComponent } from "./PolylineComponent";
import { Tooltip } from "./Tooltip";
import { GasBrakeValueType, RideData, RideInfo } from "../../type";
import { GraphDrawer } from "../drawer/GraphDrawer";
import { Button, makeStyles } from "@material-ui/core";
import { maxDesktopDrawerHeight, maxMobileDrawerHeight, toolbarHeight } from "../../theme";
import { isMobile } from "react-device-detect";

const KEYAPI = "AIzaSyDpIdWVFlAObcMdx_xmIMwHh6NrqPGF25A";

const useStyles = makeStyles(() => ({

  root: {
    height: "100%"
  },

  graphButton: {
    position: "absolute",
    bottom: 0,
    borderRadius: 0
  }
}));

type MapContainerProps = {
  google: GoogleAPI;
  rideData: RideData;
  currentRideTime: string
};

type TooltipInfo = {
  speed: number;
  rotation: number;
  acceleration: number;
  gasBrakeValue: GasBrakeValueType | null;
  time: Date;
  lat: number;
  lng: number;
};

export type onMouseEvent = (args: onMouseProps) => void;

const tooltipInitState: TooltipInfo = {
  speed: 0,
  rotation: 0,
  acceleration: 0,
  gasBrakeValue: {
    isGas: false,
    value: 0,
    percentValue: 0
  },
  time: new Date(),
  lat: 0,
  lng: 0
};

const getBounds = (google: GoogleAPI, coords: RideInfo[]) => {
  const bounds = new google.maps.LatLngBounds();
  coords.forEach((_, i) => bounds.extend(coords[i]));
  return bounds;
};

const MapContainer: FC<MapContainerProps> = ({
                                               google,
                                               rideData,
                                               currentRideTime
                                             }) => {
  const classes = useStyles();
  const { minMaxGasBrakeValue, laps } = rideData;
  const [rideWithCoords, setRideWithCoords] = useState<RideInfo[]>(rideData.rideWithCoords);
  const [bounds, setBounds] = useState<any>();
  const [isTooltip, setIsToolTip] = useState(false);
  const [isLocationMarker, setIsLocationMarker] = useState(false);
  const [center, setCenter] = useState({});
  const [tooltipInfo, setTooltipInfo] = useState<TooltipInfo>(tooltipInitState);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const coordsLength = rideWithCoords.length - 1;

  const { rotation, speed, acceleration, gasBrakeValue, lat, lng, time } =
    tooltipInfo;

  const onMouse: onMouseEvent = useCallback(
    ({ event, frontal, lat, lng, ...props }) => {
      const { latLng } = event || {};
      if (latLng) {
        setIsToolTip(true);
      }
      setIsLocationMarker(true);
      setTooltipInfo({
        gasBrakeValue: frontal,
        lat: lat ? lat : latLng.lat(),
        lng: lng ? lng : latLng.lng(),
        ...props
      });
    },
    []
  );
  const onMouseOut = useCallback(() => {
    setIsToolTip(false);
    setIsLocationMarker(false);
  }, [setIsToolTip]);

  const onClick: onMouseEvent = useCallback(({ event, frontal, lat = 0, lng = 0, ...props }) => {
    setIsLocationMarker(true);
    setTooltipInfo({
      gasBrakeValue: frontal,
      lat,
      lng,
      ...props
    });
  }, []);

  const onDrawerOpen = useCallback(() => {
    setRideWithCoords(rideData.rideWithCoords);
    setIsDrawerOpen((prev) => !prev);
    setIsToolTip(false);
  }, [rideData.rideWithCoords]);

  const onLapSelect = useCallback((ride: RideInfo[] | undefined) => {
    if (ride) {
      setRideWithCoords(ride);
    } else {
      setRideWithCoords(rideData.rideWithCoords);
    }
  }, [rideData.rideWithCoords]);

  useEffect(() => {
    setCenter({
      lat: rideWithCoords[0]?.lat || 0,
      lng: rideWithCoords[0]?.lng || 0
    });
    setBounds(getBounds(google, rideWithCoords));
  }, [rideWithCoords, google]);

  //  todo Temporary solution
  const googleMapHeight = useMemo(() => {
    if (isMobile) {
      return isDrawerOpen ? `calc(100% - ${toolbarHeight}px - ${maxMobileDrawerHeight}px)` : `calc(100% - ${toolbarHeight}px)`;
    }
    return isDrawerOpen ? `calc(100% - ${toolbarHeight}px - ${maxDesktopDrawerHeight}px)` : `calc(100% - ${toolbarHeight}px)`;
  }, [isDrawerOpen]);

  return (
    <div className={classes.root}>
      {isTooltip && (
        <Tooltip
          gasBrakeValue={gasBrakeValue}
          acceleration={acceleration}
          rotation={rotation}
          speed={speed}
          time={time}
        />
      )}
      <Map
        google={google}
        initialCenter={center}
        bounds={bounds}
        containerStyle={{
          width: "100%",
          top: toolbarHeight,
          height: googleMapHeight
        }}
      >
        {isLocationMarker && (
          <Marker
            position={{ lat, lng }}
            icon={{
              url: "/images/dot.svg",
              anchor: new google.maps.Point(15, 15)
            }}
          />
        )}
        <PolylineComponent
          {...google}
          coords={rideWithCoords}
          onMouse={onMouse}
          onMouseOut={onMouseOut}
        />
        <Marker position={rideWithCoords[0]} />
        <Marker position={rideWithCoords[coordsLength]} />
      </Map>

      <Button
        className={classes.graphButton}
        onClick={onDrawerOpen}
        variant="contained"
        color="primary"
      >
        Informace o jízdě
      </Button>
      <GraphDrawer
        open={isDrawerOpen}
        onDrawerOpen={onDrawerOpen}
        coords={rideWithCoords}
        minMaxGasBrakeValue={minMaxGasBrakeValue}
        onMouse={onMouse}
        onMouseOut={onMouseOut}
        onClick={onClick}
        laps={laps}
        currentRideTime={currentRideTime}
        onLapSelect={onLapSelect}
      />
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: KEYAPI
})(MapContainer);
