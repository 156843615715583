import React, { FC } from "react";
import { AppBar, createStyles, makeStyles, Toolbar, Tooltip, Typography } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Link, Route } from "react-router-dom";
import { ArrowBack } from "@material-ui/icons";
import { toolbarHeight } from "../../theme";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: "flex"
    },

    toolBar: {
      display: "flex",
      alignItems: "center",
      minHeight: toolbarHeight
    },
    title: {
      marginLeft: 20
    },
    link: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "#fff "
    }
  })
);

type MenuBarProps = {
  title: string;
};

export const MenuBar: FC<MenuBarProps> = ({ title }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed">
        <Toolbar className={classes.toolBar} variant="dense">
          <Route path="/rides/:id">
            <Link to="/rides" className={classes.link}>
              <Tooltip title="back" aria-label="back">
                <ArrowBack />
              </Tooltip>
            </Link>
          </Route>
          <Typography variant="subtitle2" className={classes.title}>
            {title}
          </Typography>
        </Toolbar>
      </AppBar>
    </div>
  );
};
