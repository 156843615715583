import React, { FC, useMemo } from 'react'
import { RideInfo } from '../../type'
import { Circles } from './Circles'
import { getTimeOfEachLap } from './helper'

type CirclesContainerType = {
    laps: RideInfo[][],
    onLapSelect: (ride: RideInfo[]|undefined) => void
    currentRideTime: string
}


export const CirclesContainer: FC<CirclesContainerType> = ({laps, currentRideTime, onLapSelect}) => {
    const lapTime = useMemo(() => getTimeOfEachLap(laps), [laps])

    return (
        <Circles onLapSelect={onLapSelect} lapTime={lapTime} totalRideTime={currentRideTime} laps={laps}/>
    )
}
