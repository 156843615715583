import { GasBrakeValueType, RideInfo } from '../type';
import { DrivePoint } from "../helpers/trasnform/binaryUtils";

export const getMinMaxSpeed = (coords: RideInfo[]) => {
  const uniqueSpeed = [...new Set(coords.map(({ speed }) => speed))];
  return {
    maxSpeed: Math.max(...uniqueSpeed),
    minSpeed: Math.min(...uniqueSpeed),
  };
};

export const getMinMaxGasBrakeValue = (rides: DrivePoint[]) => {
  const uniqueGasBrakeValue = [
    ...new Set(
      rides
        .filter((ride) => ride.gps && ride.rotations && ride.accelerations)
        // @ts-ignore
        .map(({ accelerations }) => accelerations.frontal)
    ),
  ];
  const minGasBrakeValue = Math.min(...uniqueGasBrakeValue);
  const maxGasBrakeValue = Math.max(...uniqueGasBrakeValue);
  return {
    maxGasBrakeValue,
    minGasBrakeValue,
  };
};

export const getPercentOfCurrentGasBrakeValue = (
  min: number,
  max: number,
  current: number
): GasBrakeValueType => {
  const shorterMax = getShortFloatNumber(max, 2);
  const shorterMin = getShortFloatNumber(min, 2);
  const shorterCurrent = getShortFloatNumber(current, 2);

  if (current > 0) {
    return {
      isGas: true,
      percentValue: Math.floor((shorterCurrent / shorterMax) * 100),
      value: shorterCurrent
    };
  }
  return {
    isGas: false,
    percentValue: Math.floor((shorterCurrent / shorterMin) * 100),
    value: shorterCurrent
  };
};

export const getShortFloatNumber = (number: number, digits: number) =>
  Number(number.toFixed(digits));
