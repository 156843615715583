import React, { FC, memo, useState } from "react";

import { Drawer, IconButton, makeStyles, Tab, Tabs } from "@material-ui/core";
import { MinMaxGasBrakeValueType, RideInfo } from "../../type";
import { isMobile } from "react-device-detect";
import { MobileGraphs } from "../graph/MobileGraphs";
import { DesktopGraphs } from "../graph/DesktopGraphs";
import { onMouseEvent } from "../googleMap/GoogleMap";

import { CirclesContainer } from "../circles/CirclesContainer";
import { TabPanel } from "../tabs/Tabs";
import { Close } from "@material-ui/icons";
import { maxDesktopDrawerHeight, maxMobileDrawerHeight, tabHoverColor } from "../../theme";

const useStyles = makeStyles(({ spacing, palette }) => ({
  actionButton: {
    borderRadius: 0
  },
  drawer: {
    maxHeight: isMobile ? maxMobileDrawerHeight : maxDesktopDrawerHeight,

    "& > div": {
      height: isMobile ? maxMobileDrawerHeight : maxDesktopDrawerHeight
    }
  },

  tabs: {
    "& .MuiTabs-fixed": {
      position: "fixed",
      backgroundColor: palette.common.white,
      zIndex: 2
    }
  },

  tab: {
    "&:hover": {
      backgroundColor: tabHoverColor
    }
  }

}));

type GraphDrawerProps = {
  open: boolean;
  currentRideTime: string
  coords: RideInfo[];
  laps: RideInfo[][];
  onDrawerOpen: () => void;
  onLapSelect: (ride: RideInfo[] | undefined) => void
  onMouse: onMouseEvent;
  onClick: onMouseEvent;
  onMouseOut: () => void;
  minMaxGasBrakeValue: MinMaxGasBrakeValueType;
};

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

export const GraphDrawer: FC<GraphDrawerProps> = memo(({
                                                         open,
                                                         onDrawerOpen,
                                                         coords,
                                                         minMaxGasBrakeValue,
                                                         onMouse,
                                                         onMouseOut,
                                                         onClick,
                                                         laps,
                                                         currentRideTime,
                                                         onLapSelect
                                                       }) => {

  const classes = useStyles();
  const [value, setValue] = useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const areLapsMoreThanOne = laps.length > 1;

  return (
    <Drawer className={classes.drawer} anchor="bottom" open={open} variant="persistent">

      <Tabs value={value} onChange={handleChange} indicatorColor="primary" className={classes.tabs}>
        <Tab label="Grafy" {...a11yProps(0)} className={classes.tab} />
        <Tab disabled={!areLapsMoreThanOne} label="Kola" {...a11yProps(1)} className={classes.tab} />
        <div>
          <IconButton onClick={onDrawerOpen} color="primary" className={classes.actionButton}>
            <Close />
          </IconButton>
        </div>
      </Tabs>

      <TabPanel value={value} index={0}>
        {isMobile ? (
          <MobileGraphs coords={coords} onClick={onClick} />
        ) : (
          <DesktopGraphs
            coords={coords}
            minMaxGasBrakeValue={minMaxGasBrakeValue}
            onMouse={onMouse}
            onMouseOut={onMouseOut}
          />
        )}
      </TabPanel>
      <TabPanel value={value} index={1}>
        <CirclesContainer onLapSelect={onLapSelect} currentRideTime={currentRideTime} laps={laps} />
      </TabPanel>
    </Drawer>
  );
});
