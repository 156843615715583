import React, { FC } from 'react';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { MinMaxGasBrakeValueType, RideInfo } from '../../../type';
import { onMouseEvent } from '../../googleMap/GoogleMap';
import { ActivePayloadType } from './LineChartComponent';

const gradientOffset = ({
  minGasBrakeValue,
  maxGasBrakeValue,
}: MinMaxGasBrakeValueType) => {
  if (maxGasBrakeValue <= 0) {
    return 0;
  }
  if (minGasBrakeValue >= 0) {
    return 1;
  }

  return maxGasBrakeValue / (maxGasBrakeValue - minGasBrakeValue);
};

type AreaChartComponentProps = {
  coords: RideInfo[];
  minMaxGasBrakeValue: MinMaxGasBrakeValueType;
  onMouse?: onMouseEvent;
};

export const AreaChartComponent: FC<AreaChartComponentProps> = ({
  coords,
  minMaxGasBrakeValue,
  onMouse,
}) => {
  const off = gradientOffset(minMaxGasBrakeValue);
  const onMouseMove = ({ activePayload }: ActivePayloadType) => {
    if (activePayload && onMouse) {
      const { payload } = activePayload[0];
      onMouse(payload);
    }
  };
  return (
    <ResponsiveContainer width="100%" height={150}>
      <AreaChart
        onMouseMove={onMouseMove}
        syncId="desktopLineChart"
        data={coords}
        margin={{
          top: 10,
          right: 30,
          left: 0,
          bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip formatter={(frontal: number) => [frontal, 'Brzda/Plyn']} separator=": " />
        <defs>
          <linearGradient id="splitColor" x1="0" y1="0" x2="0" y2="1">
            <stop offset={off} stopColor="green" stopOpacity={1} />
            <stop offset={off} stopColor="red" stopOpacity={1} />
          </linearGradient>
        </defs>
        <Area
          type="monotone"
          dataKey="frontal.value"
          stroke="#000"
          fill="url(#splitColor)"
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};
