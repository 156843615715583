import React, { FC } from "react";
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { RideInfo } from "../../../type";
import { onMouseEvent } from "../../googleMap/GoogleMap";

type ChartComponentProps = {
  dataKey: "speed" | "acceleration" | "rotation";
  ride?: RideInfo[] | undefined;
  label: string;
  color: string;
  onMouse?: onMouseEvent
  onMouseOut?: () => void;
};

export type ActivePayloadType = {
  activePayload: [
    {
      payload: RideInfo;
    }
  ];
};

export const LineChartComponent: FC<ChartComponentProps> = ({
                                                              dataKey,
                                                              ride,
                                                              onMouse,
                                                              onMouseOut,
                                                              label,
                                                              color
                                                            }) => {

  const onMouseMove = ({ activePayload }: ActivePayloadType) => {
    if (activePayload && onMouse) {
      const { payload } = activePayload[0];
      onMouse(payload);
    }
  };

  return (
    <ResponsiveContainer width="100%" height={100}>
      <LineChart
        onMouseMove={onMouseMove}
        onMouseLeave={onMouseOut}
        data={ride}
        syncId="desktopLineChart"
        margin={{
          top: 10,
          right: 30,
          left: 0,
          bottom: 0
        }}
      >
        <CartesianGrid strokeDasharray="4" />
        <XAxis padding={{ left: 10, right: 10 }} dataKey="name" />
        <YAxis />
        <Tooltip
          itemStyle={{ color: "#000" }}
          formatter={(value: any) => [Math.floor(value), label]}
          separator=": "
        />
        <Line dot={false} type="monotone" dataKey={dataKey} stroke={color} />
      </LineChart>
    </ResponsiveContainer>
  );
};
