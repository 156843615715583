const DAY = 24 * 60 * 60 * 1000;
const HOURS = 60 * 60 * 1000;
const MINUTES = 60 * 1000;

export const options = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
} as const;

export const parseDate = (date: number | string) => {
  return new Date(date).toLocaleDateString('cz-CZ', options);
};

const displayTimeWithZero = (time: number) => {
  if(time < 10) {
    return `0${time}`
  }
  if(time === 0) {
    return '00'
  }

  return time
}

export const msToTime = (ms: number) => {
  console.log(ms)
  const daysInMs = ms % DAY;
  const hours = Math.floor(daysInMs / HOURS);
  const hoursInMs = ms % HOURS;
  const minutes = Math.floor(hoursInMs / MINUTES);
  const minutesInMs = ms % MINUTES;
  const sec = Math.floor(minutesInMs / 1000);
  const milliseconds = ms % sec
  return `${displayTimeWithZero(hours)}:${displayTimeWithZero(minutes)}:${displayTimeWithZero(sec)}.${milliseconds}`;
};
