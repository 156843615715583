import { getRidesWithCoords } from '../helpers/trasnform/transformData';
import { Ride, RideData } from '../type';
import { db } from './db';

export const addNewRide = async (data: Blob, nameOfRide: string) => {
  const ridesWithCoord = await getRidesWithCoords(data, nameOfRide);
  db.ride.add(ridesWithCoord);
};

export const deleteRide = (id: string) => {
  db.ride.delete(id);
};

export const getRideById = async (id: string): Promise<RideData> => {
  const rides = await db.ride.where({ id }).toArray();
  return rides[0].data
};

export const getAllRides = (): Promise<Ride[]> => {
  return db.ride.toArray();
};
