import { timeDiff } from "../../helpers/time/timeDifference"
import { RideInfo } from "../../type"

export const getTimeOfEachLap = (laps: RideInfo[][]) => {
    return laps.reduce<string[]>((acc, cur) => {
        const firstElement = cur[0]
        const lastElement = cur[cur.length - 1]
        acc.push(timeDiff(lastElement.time, firstElement.time))
        return acc
    },[])
}
