require("regenerator-runtime");

const UART_SERVICE_UUID = "6e400001-b5a3-f393-e0a9-e50e24dcca9e";
// const UART_TO_ESP_CHARACTERISTIC = '6e400002-b5a3-f393-e0a9-e50e24dcca9e';
const UART_FROM_ESP_CHARACTERISTIC = '6e400003-b5a3-f393-e0a9-e50e24dcca9e';

let characteristic;

const decoder = new TextDecoder();
const encoder = new TextEncoder();

const getBluetoothServer = () => navigator.bluetooth.requestDevice({
  filters: [
    {namePrefix: '6sence'},
  ],
  optionalServices: [
    UART_SERVICE_UUID
  ]
}).then(device => device.gatt.connect())

const getService = server => server.getPrimaryService(UART_SERVICE_UUID)

const setupReadCharacteristic = async (service, dataCallback) => {
  const fromEspCharacteristic = await service.getCharacteristic(UART_FROM_ESP_CHARACTERISTIC);
  fromEspCharacteristic.addEventListener('characteristicvaluechanged', event => dataCallback(event.target.value));

  return await fromEspCharacteristic.startNotifications();
};

const _parseReceivedData = (data) => {
  const buffer = data.buffer;
  const string = decoder.decode(buffer);

  const command = string[0];

  // E - error
  if (command === 'E') {
    return { error: string.substring(2) };
  }

  // L - file list line
  if (command === 'L') {
    const fileListLineRegex = /L i([0-9]+) s([0-9]+) n(.+)/;
    const fileListParts = string.match(fileListLineRegex);
    if(!fileListParts) {
      return { error: `Unable to parse LINE ${string}` };
    }

    const [, index, size, name] = fileListParts;

    return {
      index: parseInt(index, 10),
      size: parseInt(size, 10),
      name,
    };
  }

  // I - info
  if (command === 'I') {
    const param = string.substring(2);

    if (param === 'No more files') {
      return { noMoreFiles: true };
    }

    if (param === 'No more data') {
      return { noMoreData: true };
    }
  }

  // B - battery
  if (command === 'B') {
    return parseInt(string.substring(2), 10);
  }

  // D - file data chunk
  if (command === 'D') {
    const fileChunkRegex = /D o([0-9]+) s([0-9]+) d/;
    const fileChunkParts = string.match(fileChunkRegex);
    if(!fileChunkParts) {
      return { error: `Unable to parse DATA ${string}` };
    }

    const [header, offset, size] = fileChunkParts;

    return {
      offset: parseInt(offset, 10),
      size: parseInt(size, 10),
      content: buffer.slice(header.length, buffer.size)
    };
  }

  // S - serial number
  if (command === 'S') {
    return {
      serialNumber: '0x' + parseInt(string.substring(2), 10).toString(16)
    }
  }

  return { error: true };
};

const parseReceivedData = (data) => {
  const r = _parseReceivedData(data);
  console.log(r);

  return r;
};

const readCharacteristic = async characteristic => await characteristic.readValue()
  .then(parseReceivedData);

const writeCharacteristic = async (characteristic, value) => await characteristic.writeValue(encoder.encode(value))

const listAllFiles = async characteristic => {
  await writeCharacteristic(characteristic, "L");

  const files = [];
  while (1) {
    const info = await readCharacteristic(characteristic);
    if (info.error || info.noMoreFiles) {
      break;
    }

    files[info.index] = {file: info.name, size: info.size}
  }

  return files;
};

// const logText = (str, append) => {
//   const pre = document.getElementById("log");

//   if (append !== undefined) {
//     pre.innerText += append + str;
//   } else {
//     pre.innerText = str;
//   }
// };

// const log = (str, append) => {
//   const json = JSON.stringify(str);
//   logText(json, append);
// };

// const deleteFile = async (info) => {
//   const fileName = info.file;
//   await writeCharacteristic(characteristic, `D ${fileName.trim()}`);

//   const result = await readCharacteristic(characteristic);
//   log(result);

//   await loadFiles();
// };

const readFile = async (info) => {
  const fileName = info.file;
  // const fileSize = info.size;
  // const fileSizeKb = (fileSize / 1024).toFixed(2);

  await writeCharacteristic(characteristic, `R ${fileName.trim()}`);

  const content = Buffer.alloc(info.size);
  while (1) {
    const chunk = await readCharacteristic(characteristic);
    if (chunk.error) {
      return null;
    }

    if (chunk.noMoreData) {
      break;
    }

    const c = new Uint8Array(chunk.content)
    for(let i = 0; i < chunk.size; i++) {
      content[chunk.offset + i] = c[i];
    }

    // const percents = (chunk.offset * 100 / fileSize).toFixed(2);

    // const chunkSize = (chunk.offset / 1024).toFixed(2);
  }

  return content;

  // const parsedFileContent = parseMessage(content);
  // const withRealTime = addRealTime(parsedFileContent);
  // return eliminateStartTime('timestamp')(withRealTime);
};

const loadFiles = async () => {
  const files = (await listAllFiles(characteristic)).sort();

  return files

//   const out = document.getElementById("files");
//   out.innerHTML = files
//     .map(({file, size}) => `${file}\t${(size / 1024).toFixed(2)} kB`)
//     .join('\n');

//   const select = document.getElementById("fileName");
//   const length = select.options.length;
//   for (let i = length - 1; i >= 0; i--) {
//     select.options[i] = null;
//   }

//   files.forEach(info => {
//     const option = document.createElement("option");
//     option.text = `${info.file}\t${(info.size / 1024).toFixed(2)} kB`;
//     option.value = JSON.stringify(info);
//     select.add(option);
//   })

};

export const loadBattery = async () => {
  await writeCharacteristic(characteristic, `B`);

  const result = await readCharacteristic(characteristic)
  return result
};

export const tryToConnect = async () => {
  return await getBluetoothServer();
}

export const connect = async (server) => {
  const service = await getService(server);
  characteristic = await setupReadCharacteristic(service, () => null);

  return await loadFiles();
}

// const initOta = async () => await writeCharacteristic(characteristic, 'O');

// const getSerialNumber = async () => {
//   await writeCharacteristic(characteristic, 'S');

//   const result = await readCharacteristic(characteristic)

//   log(result);
// };


// const loadFilesButton = document.getElementById("loadFiles");
// loadFilesButton.onclick = loadFiles;

// const fileNameInput = document.getElementById("fileName");

// const deleteFileButton = document.getElementById("deleteFile");
// deleteFileButton.onclick = async () => deleteFile(JSON.parse(fileNameInput.value));

// const initOtaButton = document.getElementById("initOta");
// initOtaButton.onclick = async () => initOta();

// const getSerialNumberButton = document.getElementById("getSerialNumber");
// getSerialNumberButton.onclick = async () => getSerialNumber();


export const downloadFile = async (info) => {
  const content = await readFile(info);
  return new Blob([content], {type: "octet/stream"})
};
