import {LatLng} from "../../database/circuits";

export const pythagorasDistance = (pos1: LatLng | undefined, pos2: LatLng | undefined): number => {
    if (!pos1 || !pos2) {
        return Infinity;
    }

    const latPow = Math.pow(pos2.lat - pos1.lat, 2);
    const lngPow = Math.pow(pos2.lng - pos1.lng, 2);

    return Math.sqrt(latPow + lngPow);
};

export const manhattanDistance = pythagorasDistance;

    /*(pos1: LatLng | undefined, pos2: LatLng | undefined): number => {
    if (!pos1 || !pos2) {
        return Infinity;
    }

    const latDiff = Math.abs(pos2.lat - pos1.lat);
    const lngDiff = Math.abs(pos2.lng - pos1.lng);

    return latDiff + lngDiff;
};

     */


export const findGlobalMinimum = <T>(points: T[], criteria: (p: T) => number): T => {
    const sorter = (p1: T, p2: T) => criteria(p1) - criteria(p2);
    const sortedByCriteria = points.sort(sorter);

    return sortedByCriteria[0];
};

export const findLocalMinima = <T>(points: T[], criteria: (p: T) => number): T[] => {
    const fitnessFn = (p: T) => p ? criteria(p) : -Infinity;
    const minima: T[] = [];

    for (let i = 0; i < points.length; i++) {
        const fitnessLeft = fitnessFn(points[i - 1]);
        const fitness = fitnessFn(points[i]);
        const fitnessRight = fitnessFn(points[i + 1]);

        if (fitness < fitnessLeft && fitness < fitnessRight) {
            minima.push(points[i]);
        }
    }

    return minima;
};