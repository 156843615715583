import React, { FC } from 'react';
import { memo } from 'react';
import { pickColor } from '../../helpers/pickColor';
import { Polyline } from '@react-google-maps/api';
import { getMinMaxSpeed } from '../../utils/utils';
import { GasBrakeValueType, RideInfo } from '../../type';
import { useMemo } from 'react';

export type onMouseProps = {
  speed: number;
  time: Date;
  event?: any;
  lat?: number;
  lng?: number;
  rotation: number,
  acceleration: number,
  frontal: GasBrakeValueType
};

type PolylineComponentProps = {
  onMouseOut: () => void;
  onMouse: (props: onMouseProps) => void;
  coords: RideInfo[];
};

export const PolylineComponent: FC<PolylineComponentProps> = memo(
  ({ coords, onMouseOut, onMouse, ...props }) => {

    const {maxSpeed, minSpeed} = useMemo(() => getMinMaxSpeed(coords), [coords])

    const items = coords.map(({speed, rotation, lng, lat, time, acceleration, frontal}: RideInfo, i: number) => {
      const nextCoord = coords[i + 1];
      const color = pickColor(maxSpeed, minSpeed, speed);
      

      const coord1 = { lng:lng, lat: lat };
      const coord2 = {
        lng: nextCoord?.lng || lng,
        lat: nextCoord?.lat || lat,
      };

      return (
        <Polyline
          {...props}
          onMouseOver={(event) => onMouse({ speed, time, event, rotation, acceleration, frontal })}
          onMouseOut={onMouseOut}
          key={Math.random()}
          path={[coord1, coord2]}
          options={{
            strokeWeight: 3,
            strokeColor: color,
            lineJoin: 'round',
          }}
        />
      );
    });

    return <>{items}</>;
  }
);
