import React, { useEffect, useState } from 'react';
import { useCallback } from 'react';
import GoogleApiWrapper from '../../components/googleMap/GoogleMap';
import { getRideById } from '../../database';
import { RideData } from '../../type';

type DetailProps = {
  setTitle: (title: string) => void;
  currentRideTime: string
  match: { params: { id: string } };
};

export default function BasicTable({ setTitle, match, currentRideTime }: DetailProps) {
  const [ride, setRide] = useState<RideData>({} as RideData);
  const {
    params: { id },
  } = match;

  const getAccurateData = useCallback(async () => {
    const rideById = await getRideById(id);
    setRide(rideById);
  }, [setRide, id]);

  useEffect(() => {
    const init = async () => {
      setTitle('Detail jízdy');
      await getAccurateData();
    };
    init();
  }, [getAccurateData, setTitle]);

  if(!ride.rideWithCoords) {
    return null
  }

  return (
    <>
      <GoogleApiWrapper currentRideTime={currentRideTime} rideData={ride} />
    </>
  );
}
