import Gradient from 'javascript-color-gradient';

const secondGradient = new Gradient();

secondGradient.setGradient('0000aa', '009900', 'ffff00', 'cc0000');

secondGradient.setMidpoint(128);

const secondColorArr = secondGradient.getArray();

const secondColorArrLength = secondColorArr.length

export const pickColor = (
  maxSpeed: number,
  minSpeed: number,
  speed: number
) => {
  const colorIndex = Math.floor((speed - minSpeed) / (maxSpeed - minSpeed + 1) * secondColorArrLength)
  return secondColorArr[colorIndex]
};
