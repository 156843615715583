import {DrivePoint} from "../trasnform/binaryUtils";
import {Circuit} from "../../database/circuits";
import {findGlobalMinimum, manhattanDistance} from "./math";

const distanceToCircuit = (drivePoint: DrivePoint) => (circuit: Circuit) => manhattanDistance(
    drivePoint?.gps,
    circuit.center
);

export const findCircuit = (circuits: Circuit[], drivePoints: DrivePoint[], thresholdDistance: number): (Circuit | undefined) => {
    const gpsPoints = drivePoints.filter(p => p.gps);
    const circuitMiddlePoint = gpsPoints[Math.floor(gpsPoints.length / 2)];
    const nearestCircuit = findGlobalMinimum(circuits, distanceToCircuit(circuitMiddlePoint));
    const distanceToNearestCircuit = distanceToCircuit(circuitMiddlePoint)(nearestCircuit);

    return distanceToNearestCircuit <= thresholdDistance ? nearestCircuit : undefined;
};
