import React, { useEffect, useState } from 'react';
import Detail from './pages/detail/Detail';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { getTimeOfRides } from './helpers/trasnform/transformData';
import { RideTime } from './type';
import { RideListContainer } from './pages/rideList/RideListContainer';
import { MenuBar } from './components/menu/MenuBar';

const App = () => {
  const [ridesWithTime, setRideTime] = useState<RideTime[]>([]);
  const [currentRideTime, setCurrentRideTime] = useState<string>('');
  const [titleMode, setTitle] = useState('Seznam jízd');

  const getData = async () => {
    const newRide = await getTimeOfRides();
    setRideTime([...newRide]);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Router>
      <div className="App">
        <MenuBar title={titleMode} />
        <Route
          exact
          path={["/","/rides"]}
          render={() => (
            <RideListContainer
              setTitle={setTitle}
              rideTime={ridesWithTime}
              getData={getData}
              setCurrentRideTime={setCurrentRideTime}
            />
          )}
        />
        <Route
          path="/rides/:id"
          render={({ match }) => (
            <Detail currentRideTime={currentRideTime} setTitle={setTitle} match={match} />
          )}
        />
      </div>
    </Router>
  );
};

export default App;

export type dataRows = {};
